.btn-circle-blanc {
  background-color: #ffffff;
  outline: none;
  color: #002b2a;
  border: none;
  width: 240px;
  height: 55px;
  @include TypoOutBold();
  font-size: 20px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  &:hover {
    color: #ffffff;
    border: 2px solid #ffffff;
    background-color: rgba(155, 174, 144);
    cursor: pointer;
  }
}
#DivLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 55px;
  display: none;
}
.btn_loader {
  position: relative;
  outline: none;
  border: none;
  width: 240px;
  height: 55px;
  border: 2px solid #ffffff;
  background-color: rgba(155, 174, 144);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0 auto;

  .img {
      width: 40px !important;
      margin: 0 !important;
  }
  img {
      width: 100%;
      height: auto;
  }
}