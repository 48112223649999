#tarteaucitronContainerBg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
}
#tarteaucitronRoot {
  #tarteaucitronAlertBig {
    background: #fff;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    #tarteaucitronDisclaimerAlert {
      @include TypoOutReg();
      margin-bottom: 1em;
      width: 60%;
      padding-left: 20px;

      h1 {
        color: #002b2a;
        font-size: 1.2em;
        line-height: 1.3;
        margin-bottom: 10px;
      }

      p {
        color: #002b2a;

        a {
          color: #002b2a;
          font-weight: bold;
        }
      }
    }

    #tarteaucitronContainerButton {
      display: flex;
      padding-right: 5% !important;

      #tarteaucitronCloseAlert {
        background: #ffffff;
        color: #002b2a;
        border: 1px solid #002b2a;
        font-size: 0.813em;
        margin-left: 7px;
        padding: 10px 10px;
        border-radius: 2px;
        border: 1px solid #9bae90;
        font-weight: 600;
      }

      #tarteaucitronPersonalize {
        font-size: 0.813em;
        background: #ffffff;
        color: #002b2a;
        border: 1px solid #002b2a;
        cursor: pointer;
        display: inline-block;
        line-height: 1.2;
        padding: 10px 10px;
        text-decoration: none;
        margin-left: 7px;
        border-radius: 2px;
        font-weight: 600;
      }
    }
  }
}
// POPUP
#tarteaucitronRoot {
  #tarteaucitronBack {
    background: $colBackgroundFondPopupTAC;
    display: none;
    height: 100%;
    left: 0;
    opacity: 0.7;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2147483646;
  }

  .containerPop {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    #tarteaucitronContainerPop {
      position: relative;
      height: fit-content;
      height: -moz-fit-content;
      height: -webkit-fit-content;

      #tarteaucitronClosePanel {
        background: $colBackgroundBtnFermerPopupTAC;
        color: $colTypoBtnFermerPopupTAC;
        cursor: pointer;
        font-size: 12px;
        font-weight: 700;
        text-decoration: none;
        padding: 4px 0;
        position: absolute;
        right: 0;
        text-align: center;
        width: 70px;
      }

      #tarteaucitronServices {
        box-shadow: 0 0 35px #575757;
        margin-top: 21px !important;
        height: fit-content !important;
        height: -moz-fit-content !important;
        height: -webkit-fit-content !important;

        #tarteaucitronMainLineOffset {
          background: $colBackgroundMainlinePopupTAC;
          border-radius: 2px;
          margin-bottom: 0;
          margin-top: 21px;
          position: relative;
          border-left: 5px solid transparent;
          margin: 0;
          overflow: hidden;
          padding: 15px 5px;

          #dialogTitle {
            font-size: 1.5em;
            text-align: center;
            color: $colTypoMainlinePopupTAC;
            margin: 15px 0 28px;
          }

          .tarteaucitronName {
            display: inline-block;
            float: left;
            margin-top: 2px;
            margin-bottom: 15px;
            text-align: left;
            width: auto !important;
            margin-left: 0 !important;
            font-size: 14px;

            span {
              display: inline-block;
              margin: 12px 0 0 10px;
              color: $colTypoMainlinePopupTAC;
            }
          }
        }
      }
    }
  }
}
#tarteaucitronAlertBig #tarteaucitronCloseAlert,
#tarteaucitronAlertBig #tarteaucitronPersonalize,
#tarteaucitronAlertBig #tarteaucitronPersonalize2,
.tarteaucitronCTAButton,
#tarteaucitron #tarteaucitronPrivacyUrl,
#tarteaucitron #tarteaucitronPrivacyUrlDialog,
#tarteaucitronRoot .tarteaucitronDeny,
#tarteaucitronRoot .tarteaucitronAllow {
  font-size: 0.813em;
  background: #9bae90;
  width: 160px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  line-height: 1.2;
  padding: 10px 10px;
  text-decoration: none;
  margin-left: 7px;
  border-radius: 2px;
  font-weight: 600;
}
#tarteaucitronRoot .tarteaucitronDeny {
  background: #9bae90;
}
@media (max-width: 1325px) {
  #tarteaucitronAlertBig #tarteaucitronDisclaimerAlert {
    width: 50%;
    padding-left: 20px;
  }
}
@media (max-width: 815px) {
  #tarteaucitronRoot {
    #tarteaucitronAlertBig {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  #tarteaucitronAlertBig #tarteaucitronPrivacyUrl,
  #tarteaucitronAlertBig #tarteaucitronPrivacyUrlDialog,
  #tarteaucitronAlertBig #tarteaucitronDisclaimerAlert,
  #tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
    width: 100%;
    padding: 0;
  }
  #tarteaucitronContainerButton {
    padding: 0 !important;
  }
}
@media (min-width: 550px) {
  #tarteaucitronRoot h1 {
    font-size: 1.5rem;
  }
}
@media (max-width: 450px) {
  #tarteaucitronContainerButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  #tarteaucitronContainerButton button {
    width: 100%;
    margin-top: 15px;
  }
  #tarteaucitronAlertBig #tarteaucitronCloseAlert,
  #tarteaucitronAlertBig #tarteaucitronPersonalize {
    margin: 0;
    margin-bottom: 15px;
    width: 90%;
  }
}
.tarteaucitronSelfLink {
  display: none !important;
}
@media (max-width: 768px) {
  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer,
  #tarteaucitron {
    margin-top: 20px !important;
  }
  #tarteaucitronRoot .tarteaucitronH1 {
    margin: 50px 0 10px !important;
  }
  #tarteaucitronContainerPop {
    height: 100%;
  }
  #tarteaucitronAlertSmall
    #tarteaucitronCookiesListContainer
    #tarteaucitronClosePanelCookie,
  #tarteaucitron #tarteaucitronClosePanel {
    position: absolute !important;
    top: 0;
    right: 0;
    z-index: 10;
  }
  #tarteaucitronAlertSmall
    #tarteaucitronCookiesListContainer
    #tarteaucitronCookiesList
    .tarteaucitronTitle,
  #tarteaucitron #tarteaucitronServices .tarteaucitronTitle button,
  #tarteaucitron #tarteaucitronInfo,
  #tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
    margin: 0 !important;
  }
  #tarteaucitronRoot .containerPop {
    overflow-y: scroll;
  }
  #tarteaucitron {
    position: fixed;
    overflow-y: scroll;
    margin-top: 20px;
    height: fit-content !important;
    height: -moz-fit-content !important;
    height: -webkit-fit-content !important;
  }
  div#tarteaucitronServices {
    margin-top: 0 !important;
  }
}
#tarteaucitronRoot #tarteaucitronAlertBig {
  background: #333;
  color: #fff;
  display: none;
  font-size: 15px !important;
  left: 0;
  right: 0;
  position: fixed;
  box-sizing: content-box;
  z-index: 2147483645;
  text-align: center;
  padding: 10px 30px;
  margin: auto;
}
#tarteaucitronRoot #tarteaucitronAlertBig {
  background: #fff;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /*box-shadow: 0px 0px 20px 0px rgba(0 0 0 0.3);*/
}
@media (min-width: 550px) {
  #tarteaucitronRoot h1 {
    font-size: 1.5rem;
  }
}
@media (max-width: 1200px) {
  #tarteaucitronRoot #tarteaucitronAlertBig {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #tarteaucitronAlertBig #tarteaucitronPrivacyUrl,
  #tarteaucitronAlertBig #tarteaucitronPrivacyUrlDialog,
  #tarteaucitronRoot #tarteaucitronAlertBig #tarteaucitronDisclaimerAlert,
  #tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
    width: 100%;
    padding: 0;
  }
  #tarteaucitronContainerButton {
    padding: 0 !important;
  }
}
@media (max-width: 620px) {
  #tarteaucitronContainerButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  #tarteaucitronContainerButton button {
    width: 100%;
    margin-top: 15px;
  }
  #tarteaucitronRoot #tarteaucitronAlertBig #tarteaucitronCloseAlert,
  #tarteaucitronAlertBig #tarteaucitronPersonalize,
  #tarteaucitronRoot .tarteaucitronAllow,
  #tarteaucitronRoot .tarteaucitronDeny,
  #tarteaucitronAlertBig #tarteaucitronPersonalize2,
  .tarteaucitronCTAButton,
  #tarteaucitronRoot
    #tarteaucitronAlertBig
    #tarteaucitronContainerButton
    #tarteaucitronCloseAlert {
    margin: 0;
    margin-left: 0;
    margin-top: 15px;
    width: 90%;
  }
}
