$colBase : #7093b0;

/* CONTENU */
$colBackgroundButtonStickyMob : $colBase;
$colTypoButtonStickyMob : #ffffff;
$colBorderTopButtonStickyMob : #ffffff;

/* FORM */
$colBackgroundForm : #ffffff;
$colBackgroundHeaderForm : $colBase;
$colTypoHeaderForm : #ffffff;
$colBackgroundSubHeaderForm : #ffffff;
$colTypoSubHeaderForm : #ffffff;
$colTypoForm : #202e44;
$colBackgroundInputText : #ffffff;
$colTypoInputText : #202e44;
$colTypoPlaceholder : #202e44;
$colTypoMentionForm : #202e44;

/* FOOTER */
$colBackgroundFooter : #ffffff;
$colTypoFooter : #202e44;
$colSeparateurFooter : #202e44;

/* MODALS */
$colBackgroundModal : #ffffff;
$colTypoModal : #202e44;
$colTypoTitleModal :  #202e44;
// MODALS THANKS
$colTypoTitleThanks : #202e44;
$colTypoTextThanks : #202e44;
$colTypoCtaThanks : #ffffff;
$colBackgroundCtaThanks : #202e44;
$colBorderCtaThanks : #ffffff;

/* TARTEAUCITRON */
// BANDEAU
$colTypoTitleBandeauTAC : #202e44;
$colTypoTextBandeauTAC : #202e44;
// Personnaliser mes choix
$colBackgroundCtaMesChoixTAC : #ffffff;
$colTypoCtaMesChoixTAC : #202e44;
$colBorderCtaMesChoixTAC : #202e44;
// ACCEPTER / REFUSER
$colBackgroundBtnTAC : #273083;
$colTypoBtnTAC : #ffffff;

// POPUP
$colBackgroundFondPopupTAC : #eee;
$colBackgroundBtnFermerPopupTAC : #696969;
$colTypoBtnFermerPopupTAC : #ffffff;
$colBackgroundMainlinePopupTAC : #ffffff;
$colTypoMainlinePopupTAC : #696969;

// VISUEL
$colTypoTitleVisuel : #ffffff;

// BASICCIV
$colTypoBasicCiv : #202e44;
$colBorderRadioBasicCiv : #202e44;
$colbgRadioBasicCiv : #ffffff;
$colPuceRadioBasicCiv : #7093b0;

// SWITCHCIV
$colTypoSwitchCiv : #202e44;
$colBackgroundSwitchCiv : #ffffff;
$colPuceSwitchCiv : #7093b0;

// SWITCHOPTIN
$colTypoSwitchOptin : #202e44;
$colBackgroundSwitchOptin : #7093b0;

/* GENERAL */
$colTypo : #202e44;

$bgForm : #ffffff;
$bgHeaderForm : #7093b0;
$bgSubbgHeaderForm : #7093b0;
$typoForm : #202e44;
$typoInput : #202e44;
$bgInputs : #ffffff;
$typoPlaceholder : #202e44;
$bgRadio : #ffffff;
$borderRadio : #202e44;
$puceRadio : #7093b0;
$typoMentionFrom : #202e44;
    $colCtaForm : #202e44;
    $colTypoCtaForm : #ffffff;

$colBlanc : #ffffff;
$colNoir : #000000;
$colBg : #ffffff;
$colCta : #202e44;
$colBgSection : #202e44;
$colVert : #202e44;
$colBleu : #202e44;
$colRose : #202e44;
$bgBtnForm: #202e44;
$typoBtnForm: #ffffff;

$colTypoFormTitre : #202e44;
$colTypoFormDesc : $colNoir;
$colTypoFormInputs : #ffffff;
$colError : red;