#optin, #civ {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;

  @media (max-width: 1023px) {
    flex-direction: column;
  }

  .indicator {
    text-align: justify;
    @include TypoOutBold();
    color: #ffffff;
    position: relative;
    font-size: 22px;
    color: #ffffff;
    line-height: 140%;
    margin-top: 10px;

    @media (max-width: 1024px) {
      font-size: 14px;
    }

    a {
      display: contents;
      color: $typoForm;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .error {
    color: red;
    font-size: 12px;
    padding-top: 0px;
    position: absolute;
    left: 0;
    top: 30px;
    width: 100%;
    @include TypoOutBold();
  }

  .container-radio {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    :first-child {
      margin-right: 90px;

      @media (max-width: 768px) {
        margin-right: 50px;
      }
    }

    .radio {
      display: block;
      position: relative;
      padding-left: 40px;
      cursor: pointer;
      font-size: 20px;
      color: #ffffff;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      line-height: 140%;

      @media (max-width: 1024px) {
        font-size: 14px;
        padding-left: 30px;
      }

      :first-child {
        margin-right: 0;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      .checkmark-radio {
        position: absolute;
        left: 0;
        height: 25px;
        width: 25px;
        top: 2px;
        background-color: $bgRadio;
        border: none;
        border-radius: 50%;

        @media (max-width: 1024px) {
          top: 0;
          height: 18px;
          width: 18px;
        }
      }

      input:checked ~ .checkmark-radio {
        background-color: #ffffff;
      }

      .checkmark-radio:after {
        content: "";
        position: absolute;
        display: none;
      }

      input:checked ~ .checkmark-radio:after {
        display: block;
        background-color: #002b2a;
      }

      .checkmark-radio:after {
        top: 5px;
        left: 5px;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        background: #002b2a;

        @media (max-width: 1024px) {
          top: 4px;
          left: 4px;
          height: 10px;
          width: 10px;
        }
      }
    }
  }
}
#civ {
  margin: 0;
  align-items: flex-start;

  @media (max-width: 1024px) {
    align-items: center;
  }
  .container-radio {
      margin: 0;
  }
}