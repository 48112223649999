// BLOC FORMULAIRE

.form {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
  flex-flow: wrap;
  background-color: #002b2a;
  padding: 70px 40px;

  @media (max-width: 768px) {
    padding: 40px 20px;
  }

  .form-inner {
    @include TypoOutBold();
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    z-index: 9;

    ///////////
    // TITRE //
    ///////////

    .header-form {
      width: auto;
      @include FullCenter();
      flex-direction: column;
      position: relative;

      h2 {
        @include TypoPlayBold();
        font-size: 30px;
        line-height: 140%;
        color: #9bae90;
        @include FullCenter();
        text-align: center;
        flex-direction: column;
        text-transform: uppercase;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
      .white {
        color: #ffffff;
      }
    }
    .img {
      width: 200px;
      transform: rotate(180deg);
      margin-top: 40px;
      margin-bottom: 45px;

      @media (max-width: 768px) {
        width: 150px;
      }
    }
    ////////////
    // CHAMPS //
    ////////////

    .container-form {
      width: auto;
      max-width: 1100px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 !important;
      padding-top: 25px;

      @media (max-width: 1024px) {
        max-width: 470px;
      }

      .form-item {
        position: relative;
        width: 48%;
        @include FullCenter();

        @media (max-width: 1024px) {
          width: 100%;
        }

        & + .form-item {
          margin-top: 10px;
          @include FullCenter();
          flex-direction: column;
        }

        .easy-autocomplete {
          width: 100% !important;

          & > input {
            min-width: 100% !important;
            box-shadow: 0 0 black !important;
          }
        }

        a {
          color: $colTypoForm;
          text-decoration: underline;
        }

        ////////////
        // INPUTS //
        ////////////

        input[type="text"],
        input[type="email"],
        input[type="tel"],
        input[type="number"] {
          @include TypoOutBold();
          font-size: 22px;
          font-family: arial;
          width: 100%;
          display: block;
          background-color: rgba(155, 174, 144, 0.5) !important;
          border: none;
          height: 58px;
          color: #ffffff !important;
          padding: 0;
          padding-left: 30px;
          margin-top: 25px;

          @media (max-width: 1024px) {
            font-size: 16px;
            text-align: center;
            padding-left: 0;
            padding: 0 10px;
          }

          &::placeholder {
            color: #ffffff;
          }

          /*&:hover {
            opacity: 1;
            cursor: pointer;
          }

          &:valid,
          &:focus {
            opacity: 1;
            border-bottom: 1px solid #202e44;
          }*/
        }
        ///////////
        // RADIO //
        ///////////

        .left {
          margin-right: 10px;
        }        

        ////////////////
        // VALIDATION //
        ////////////////

        .error {
          color: red;
          font-size: 12px;
          padding-top: 10px;
        }

        .container-radio {
          margin-top: 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
      }

      ////////////////////////
      // Habiter / Investir //
      ////////////////////////

      .habinv {

        display: flex;
        flex-direction: column;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        margin-bottom: 5px;

        .label-habinv {
          margin-bottom: 8px;
        }

        .habinv-container {
          border: 1px solid $colPuceSwitchCiv;
          width: 170px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 14px 0px;


          label {
            width: 49%;
            text-align: center;
          }
        }

        label {
          @include TypoOutBold();
          font-size: 13px;
        }
        .label-radio {
          cursor: pointer;
        
          & + .label-radio {
            margin-left: 0px;
          }
        
          .indicator {
            @include TypoOutBold();
            color: #7093b0;
            position: relative;
            font-size: 13px;
            padding: 7px 11px;
            padding-left: 11px !important;
            text-align: center;
          }
        
          .indicator:before {
            width: 20px;
            height: 20px;
            border: 1px solid $colBorderRadioBasicCiv;
            background-color: $colbgRadioBasicCiv;
            border-radius: 100%;
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
            left: 0;
            display: none;
          }
        
          .indicator:after {
            width: 0px;
            height: 0px;
            background: $colPuceRadioBasicCiv;
            border-radius: 100%;
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
            left: 4px;
            display: none;
            -webkit-transform: scale(0);
            -ms-transform: scale(0);
            transform: scale(0);
            -webkit-transition-duration: 0.3s;
            -o-transition-duration: 0.3s;
            transition-duration: 0.3s;
          }

          input:checked + .indicator {
            background: $colPuceRadioBasicCiv;
            color: #ffffff;
          }
        
          input {
            display: none;
          }
        
          input:checked + .indicator:after {
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
          }
        }
        .fst-lab {
          margin-right: 30px;
        
          @media (max-width: 575px) {
            margin-right: 20px;
          }
        }
      }

      .civ {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-left: 5px;
        margin-bottom: 20px;
      }

      .button-vald {
        width: 100%;
      }

      .form-mentions {
        @include TypoOutBold();
        text-align: justify;
        @include FullCenter();
        flex-direction: column;
        font-size: 11px;
        line-height: 18px;
        width: 100%;
        color: #202e44;
        margin-bottom: 30px;
        //word-spacing: -2px;

        span {
          @include TypoOutBold();
          font-size: 14px;
          margin: 0 auto;
          margin-top: 15px;
          word-spacing: 0;
          color: #ffffff;
          font-weight: normal;
        }

        a {
          display: contents;
          color: $colTypoMentionForm;
          text-decoration: underline !important;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.deskHide {
  display: none;
}
.mention-log {
  width: 591px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  .mentions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .separation {
      color: #ffffff;
      margin: 0 5px;
    }

    a,
    button {
      border: none;
      background-color: transparent;
      @include TypoOutBold();
      text-decoration: underline;
      color: #ffffff;
      font-size: 11px;
  
      &:hover {
        text-decoration: none;
      }
    }
  }
  .logos {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;

    .covivio {
      width: 50px;
      margin-right: 15px;
    }

    .bordeaux {
      width: 50px;
    }
  }
}
.mentions-mob {
  display: none;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  margin-top: 25px;
  margin-bottom: 50px;

  a,
  button {
    border: none;
    background-color: transparent;
    @include TypoOutBold();
    color: #202e44;
    font-size: 11px;
  }
  .separation {
    color: #202e44;
    margin: 0 5px;
  }

  a,
  button {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
.subBtn {
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
}