// FOOTER
footer {
  position: relative;
  bottom: 0;
  margin: 0;
  width: 100%;
  height: 40px;
  background-color: #002b2a;
  z-index: 50;
  @include FullCenter();

  @media (max-width: 575px) {
    height: auto;
    position: relative;
  }

  .footer-items {
    @include FullCenter();
    flex-direction: row;

    @media (max-width: 575px) {
      flex-direction: column;
      margin: 40px 40px 40px 40px;
    }

    a {
      color: #ffffff;
      @include TypoOutReg();
      font-size: 14px;
      margin: 0 10px;
      text-align: center;

      @media (max-width: 575px) {
        margin: 10px;
      }
    }

    .separation {
      border-left: 1px solid #ffffff;
      height: 15px;

      @media (max-width: 575px) {
        transform: rotateZ(90deg);
      }
    }
  }
}