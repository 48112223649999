//////////////////////////
// MODALS REMERCIEMENTS //
//////////////////////////

#modal-thanks {
  text-align: center;

  .modal-content {
    background-color: #9bae90;
  }
  h2 {
    margin: 0;
    @include TypoOutBold();
    font-size: 40px;
    color: #1f2c3c;
    margin-bottom: 30px;
  }

  p {
    margin: 0;
    @include TypoOutBold();
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 30px;

    @media (max-width: 450px) {
      font-size: 18px;
    }
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #002b2a;
    outline: none;
    color: #ffffff;
    border: none;
    width: 240px;
    padding: 15px 0;
    @include TypoOutReg();
    font-size: 20px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;

    @media (max-width: 768px) {
      font-size: 16px;
    }

    &:hover {
      color: #002b2a;
      background-color: #ffffff;
      cursor: pointer;
    }
  }

  .modal-body {
    padding: 50px 25px;
  }

  .modal-dialog {
    max-width: 530px;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    margin: 0;
  }
}

@media (max-width: 767px) {
  #modal-thanks .modal-dialog {
    width: calc(100% - 40px);
  }
  #modal-thanks h2 {
    margin-bottom: 15px;
  }
}
@media (min-width: 767px) {
  .modal-content {
    padding: 30px;
  }
}
#modal-thanks .modal-content {
  border-radius: 0;
  border: none;
  position: relative;
  z-index: 1;
}
#modal-thanks .startflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
/*
#modal-thanks .custom_close {
  width: 100%;
  text-align: center;
}
#modal-thanks .close {
  width: 44px;
  height: 44px;
  display: block;
  margin: 0 auto;
  border-radius: 50%;
  color: #d5694e;
  border: 1px solid #d5694e;
  cursor: pointer;
  text-align: center;
  line-height: 43px;
  font-size: 30px;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  position: relative;
  float: none;
  padding: 0;
}
#modal-thanks .close:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 21px;
}
#modal-thanks .close:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: 21px;
}
#modal-thanks .close:before,
#modal-thanks .close:after {
  display: block;
  height: 36px;
  position: absolute;
  content: "";
  background: #d5694e;
  width: 1px;
  top: 3px;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
#modal-thanks .close:hover:before,
#modal-thanks .close:hover:after {
  background: #fff;
}
#modal-thanks .close:hover {
  background: #d5694e;
}
@media (max-width: 767px) {
  #modal-thanks h2 {
    font-size: 30px;
  }
  #modal-thanks .start_big {
    font-size: 22px;
    line-height: 1.4;
  }
  #modal-thanks p {
    font-size: 16px;
    margin-bottom: 20px;
  }
}
*/
//////////////////////////////////
// COOKIES / MENTIONS / DONNEES //
//////////////////////////////////

#mentionsLegales .close:not(:disabled):not(.disabled),
#donnees .close:not(:disabled):not(.disabled),
#cookies .close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

#mentionsLegales button.close,
#donnees button.close,
#cookies button.close {
  color: $colNoir !important;
}

#mentionsLegales .close,
#donnees .close,
#cookies .close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: $colNoir;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

#mentionsLegales .modal-header .close,
#donnees .modal-header .close,
#cookies .modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

#mentionsLegales button.close,
#donnees button.close,
#cookies button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

#mentionsLegales .modal-body a {
  text-decoration: underline;
}
#mentionsLegales .modal-dialog,
#donnees .modal-dialog,
#cookies .modal-dialog {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
}
#mentionsLegales .modal-content,
#donnees .modal-content,
#cookies .modal-content {
  padding: 10px;
  text-align: justify;
  border: 0 !important;
  border-radius: 0 !important;

  @media (max-width: 576px) {
    text-align: left;
  }
}
#mentionsLegales .modal-header,
#donnees .modal-header,
#cookies .modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  border: none;
}
#mentionsLegalesTitle.modal-title,
#donneesTitle.modal-title,
#cookiesTitle.modal-title {
  color: $colTypoTitleModal;
  @include TypoOutBold();
  float: left;
  text-align: left;
  font-size: 28px !important;
  line-height: 140%;

  @media (max-width: 576px) {
    font-size: 24px !important;
    line-height: 120%;
  }
}
  #mentionsLegales .modal-dialog,
  #donnees .modal-dialog,
  #cookies .modal-dialog {
    max-width: 60%;
    height: calc(95vh - 40px);
    margin: 0 auto;

    @media (max-width: 768px) {
      max-width: none;
      width: 95%;
    }
  }

#donnees {
  h1 {
    @include TypoOutBold();
    font-size: 20px;
    margin-bottom: 20px;
  }
  h2 {
    @include TypoOutBold();
    font-size: 16px;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 20px;
  }
  i {
    font-style: italic;
  }
  ul {
    list-style: inside;
    margin-bottom: 20px;
  }
  a {
    text-decoration: underline;
  }
}
#mentionsLegales,
#cookies,
#donnees {
  height: auto;
  top: auto;
  bottom: 40px;

  .modal-body {
    @media (max-width: 768px) {
      padding: 0 10px;
    }
  }
  .modal-content {
    background-color: #9bae90;
    padding-bottom: 60px;

    @media (max-width: 768px) {
      padding-bottom: 30px;
    }
  }
  .close {
    opacity: 1;
    span {
      color: #ffffff;
    }
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  h2 {
    @include TypoPlayBold();
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    line-height: 130%;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
  .img {
    width: 200px;
    margin-top: 40px;
    margin-bottom: 50px;

    @media (max-width: 768px) {
      width: 100px;
      margin-top: 20px;
      margin-bottom: 30px;
    }
  }
  p {
    @include TypoOutReg();
    font-size: 17px;
    text-align: center;
    margin-bottom: 15px;
    text-align: justify;
    color: #ffffff;
    width: 100%;

    @media (max-width: 768px) {
      font-size: 12px;
      margin-bottom: 10px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    @include TypoOutReg();
    font-size: 17px !important;
    color: #ffffff !important;
    text-decoration: underline;
    display: block;

    @media (max-width: 768px) {
      font-size: 12px !important;
    }
  }
  ul {
    list-style: none;
    width: 100%;
    margin-left: 30px;
    margin-bottom: 15px;
    
    @media (max-width: 768px) {
      margin-bottom: 10px;
    }

    li {
      @include TypoOutReg();
      font-size: 17px;
      text-align: center;
      text-align: justify;
      color: #ffffff;

      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
}
.modal-backdrop.show {
  opacity: 0;
}