label {
  @include TypoOutBold();
  font-size: 13px;
}
.label-radio {
  cursor: pointer;
}
.fst-lab {
  margin-right: 30px;

  @media (max-width: 575px) {
    margin-right: 20px;
  }
}
#civ {
  .error {
    color: red;
    font-size: 12px;
    padding-top: 10px;
    position: absolute;
    left: 0;
    width: 100%;
    @include TypoOutBold();
    border: none;
    top: 40px;
  }
}
#optin {
  .error {
    color: red;
    font-size: 12px;
    padding-top: 10px;
    position: absolute;
    left: 0;
    width: 100%;
    @include TypoOutBold();
    border: none;
    top: 40px;
  }
}
.container_opt {
  position: relative;
}
.civ {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    max-width: 450px;
  }

  input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }
  label {
    display: inline-block;
    background-color: transparent;
    padding: 12px 52px;
    @include TypoOutBold();
    font-size: 16px;
    color: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 4px;

    @media (max-width: 768px) {
      padding: 12px 20px;
    }
  }
  input[type="radio"]:checked + label {
    background-color: #ffffff;
    color: #3c768e;
  }
  .mme {
    margin-right: 10px;
  }
  .mr {
    margin-left: 10px;
  }
}
.optin {
  position: relative;
  margin-top: 5px;
  text-align: center;

  @media (max-width: 768px) {
      max-width: 500px;
  }
  .indicator {
    @include TypoOutBold();
    font-size: 14px;
    color: #ffffff;
    width: 100%;
    text-align: center;
    line-height: 120%;
    margin-top: 25px;

    

    a {
      @include TypoOutBold();
      font-size: 14px;
      color: #ffffff;
      line-height: 120%;
      text-decoration: underline;
    }
  }
  input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }
  label {
    display: inline-block;
    background-color: transparent;
    padding: 10px 20px;
    @include TypoOutBold();
    font-size: 14px;
    color: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 4px;
  }
  input[type="radio"]:checked + label {
    background-color: #ffffff;
    color: #3c768e;
  }
  .oui {
    margin-right: 10px;
  }
  .non {
    margin-left: 10px;
  }
}