.visuel-slider {
  width: 100%;
  height: 700px;
  position: relative;

  @media (max-width: 1024px) {
    height: 420px;
}

  .glide {
    width: 100%;
    height: 100%;
    position: relative;

    .glide__track {
      width: 100%;
      height: 100%;
      overflow: hidden;

      ul {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        li {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }

        .slide1 {
          background-image: url("/images/Arpajon_Jardin-V05_Final.jpg");
        }
        .slide2 {
          background-image: url("/images/Arpajon_Rue-V05_Final.jpg");
        }
        /*.slide3 {
          background-image: url("/images/arpajon-jrd-v-03.jpg");
        }
        .slide4 {
          background-image: url("/images/arpajon-jrd-v-03.jpg");
        }*/
      }
    }

    .btnGlide {
      position: absolute;
      height: 100%;
      border: none;
      width: 50px;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      padding: 0;

      @media (max-width: 767px) {
        width: 30px;
      }

      .containerBtn {
        background: linear-gradient(
          transparent,
          rgba(0, 0, 0, 0.25),
          transparent
        );
        height: 300px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media (max-width: 767px) {
          height: 100px;
        }
        img {
          width: 20px;
          height: auto;
          @media (max-width: 767px) {
            width: 15px;
          }
        }
      }
    }
    .glideLeft {
      left: 0;
      top: 0;
    }
    .glideRight {
      right: 0;
      top: 0;
    }
  }
  /*.glide-mob {
    width: 100%;
    height: 100%;
    position: relative;

    .glide__track {
      width: 100%;
      height: 100%;

      ul {
        width: 100%;
        height: 100%;

        li {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }

        .slide1 {
          background-image: url("/images/galerie-desktop/slider1.png");
          @media (max-width: 1439px) {
            background-image: url("/images/galerie-tab/slider1.png");
          }
          @media (max-width: 767px) {
            background-image: url("/images/galerie-mob/slider1.png");
          }
        }
        .slide2 {
          background-image: url("/images/galerie-desktop/slider2.png");
          @media (max-width: 1439px) {
            background-image: url("/images/galerie-tab/slider2.png");
          }
          @media (max-width: 767px) {
            background-image: url("/images/galerie-mob/slider2.png");
          }
        }
        .slide3 {
          background-image: url("/images/galerie-desktop/slider3.png");
          @media (max-width: 1439px) {
            background-image: url("/images/galerie-tab/slider3.png");
          }
          @media (max-width: 767px) {
            background-image: url("/images/galerie-mob/slider3.png");
          }
        }
        .slide4 {
          background-image: url("/images/galerie-desktop/slider4.png");
          @media (max-width: 1439px) {
            background-image: url("/images/galerie-tab/slider4.png");
          }
          @media (max-width: 767px) {
            background-image: url("/images/galerie-mob/slider4.png");
          }
        }
      }
    }

    .btnGlide {
      position: absolute;
      height: 100%;
      border: none;
      width: 50px;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      padding: 0;

      @media (max-width: 767px) {
        width: 30px;
      }

      .containerBtn {
        background: linear-gradient(
          transparent,
          rgba(0, 0, 0, 0.25),
          transparent
        );
        height: 300px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media (max-width: 767px) {
          height: 100px;
        }
        img {
          width: 20px;
          height: auto;
          @media (max-width: 767px) {
            width: 15px;
          }
        }
      }
    }
    .glideLeft {
      left: 0;
      top: 0;
    }
    .glideRight {
      right: 0;
      top: 0;
    }
  }*/
  .glide__bullets {
    position: absolute;
    bottom: 10px;
    z-index: 999;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .glide__bullet {
      background-color: transparent;
      border: none;
      background-image: url("/images/pagination-off.svg");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      height: 10px;
      width: 14px;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
    .glide__bullet--active {
        background-image: url("/images/pagination-on.svg");
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
    }
  }
}
.slider-desk {
  @media (max-width: 1439px) {
    display: none;
  }
}
