.container-arpajon {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .header {
        width: 100%;
        height: 800px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;

        @media (max-width: 1300px) {
            height: 700px;
        }
        @media (max-width: 1024px) {
            height: auto;
            flex-direction: column;
        }

        .left {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            @media (max-width: 1024px) {
                width: 100%;
                margin-bottom: 30px;
            }

            .img {
                width: 200px;
                margin-top: 30px;

                @media (max-width: 768px) {
                    width: 100px;
                    margin-top: 20px;
                }
            }

            .text {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                margin-top: 130px;

                @media (max-width: 768px) {
                    margin-top: 10px;
                }

                h1 {
                    @include TypoPlayBold();
                    font-size: 40px;
                    color: #002b2a;
                    line-height: 150%;
                    text-transform: uppercase;
                    text-align:center;
                    line-height: 44px;
                    @media (max-width: 1440px) {
                        font-size: 30px;
                        line-height: 34px;
                    }
                    @media (max-width: 768px) {
                        font-size: 20px;
                        line-height: 24px;
                    }
                }
                p {
                    @include TypoPlayReg();
                    font-size: 23px;
                    color: #002b2a;
                    margin-bottom: 50px;

                    @media (max-width: 768px) {
                        font-size: 18px;
                    }
                }

                .bg {
                    position: relative;
                    width: 340px;
                    height: 135px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: #9bae90;

                    @media (max-width: 1440px) {
                        width: 280px;
                        height: 110px;
                    }
                    @media (max-width: 768px) {
                        width: 240px;
                        height: 90px;
                    }

                    p {
                        @include TypoPlayReg();
                        font-size: 22px;
                        color: #ffffff;
                        margin-bottom: 15px;
                        text-transform: uppercase;
                        text-align:center;
                        line-height: 26px;
                        @media (max-width: 1440px) {
                            font-size: 20px;
                            margin-bottom: 5px;
                            line-height: 24px;
                        }
                        @media (max-width: 768px) {
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }
                    span {
                        @include TypoPlayBold();
                        font-size: 42px;
                        color: #ffffff;
                        text-transform: uppercase;

                        @media (max-width: 1440px) {
                            font-size: 32px;
                        }
                        @media (max-width: 768px) {
                            font-size: 22px;
                        }
                    }

                    .picto {
                        width: 150px;
                        height: 175px;
                        position: absolute;
                        right: -125px;
                        bottom: 0;

                        @media (max-width: 1440px) {
                            width: 100px;
                            height: 133px;
                            right: -82px;
                        }
                        @media (max-width: 768px) {
                            width: 75px;
                            height: 87px;
                            right: -30px;
                            top: -25px;
                        }
                    }
                }
            }
        }
        .right {
            position: relative;
            width: 50%;
            height: 100%;
            background-color: #9bae90;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;

            @media (max-width: 1024px) {
                width: 100%;
            }
            @media (max-width: 500px) {
                padding: 10px;
            }

            .patch_maj {
                position: absolute;
                top: 40px;
                left: 40px;
                z-index: 99;
                width: 180px;
                @media (max-width: 1440px) {
                    top: 25px;
                    left: 25px;
                }
                @media (max-width: 1024px) {
                    top: 5px;
                    left: 5px;
                }
                @media (max-width: 768px) {
                    width: 120px;
                }
                @media (max-width: 450px) {
                    width: 90px;
                }
            }

            .img {
                position: relative;
                border: 2px solid #ffffff;
                padding: 20px;

                @media (max-width: 500px) {
                    padding: 10px;
                }
            }
            .patch {
                width: 260px;
                position: absolute;
                right: -2px;
                bottom: -2px;

                @media (max-width: 1440px) {
                    width: 200px;
                }
                @media (max-width: 500px) {
                    width: 120px;
                }
            }
        }
    }

    .pictos {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        margin-top: 100px;
        margin-bottom: 120px;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            margin-top: 30px;
            margin-bottom: 40px;
        }

        .item {
            width: 270px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            @media (max-width: 1024px) {
                width: 200px;
            }

            .img {
                width: 80px;
                min-height: 80px;
                margin-bottom: 20px;

                @media (max-width: 1024px) {
                    width: 75px;
                    min-height: 75px;
                }
            }

            p {
                @include TypoOutReg();
                font-size: 20px;
                color: #002b2a;
                text-align: center;
                width: 100%;

                @media (max-width: 1024px) {
                    font-size: 18px;
                }
            }
        }
        .line {
            width: 2px;
            height: 140px;
            background-color: #002b2a;
            margin: 0 25px;

            @media (max-width: 768px) {
                width: 20px;
                height: 2px;
                background-color: #9bae90;
                margin: 20px 0;
            }
        }
    }

    .parenthese {
        width: 100%;
        padding: 0 16px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 120px;

        @media (max-width: 1024px) {
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
        }
        @media (max-width: 768px) {
            margin-bottom: 40px;
        }

        .pers {
            width: 700px;

            @media (max-width: 1024px) {
                width: 100%;
                padding: 0 17px;
            }
        }

        .descr {
            width: 600px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding-left: 20px;

            @media (max-width: 1024px) {
                width: 100%;
                padding-left: 0;
                padding: 0 17px;
                margin-top: 40px;
                align-items: center;
            }
            @media (max-width: 1024px) {
                margin-top: 30px;
            }
            

            h2 {
                width: 100%;
                max-width: 515px;
                @include TypoPlayBold();
                font-size: 40px;
                color: #002b2a;
                line-height: 130%;
                margin-bottom: 45px;
                text-transform: uppercase;

                @media (max-width: 1440px) {
                    font-size: 30px;
                    text-align: center;
                }
                @media (max-width: 768px) {
                    font-size: 20px;
                    margin-bottom: 30px;
                    max-width: 275px;
                }
            }
            .bot {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-between;

                @media (max-width: 1024px) {
                    max-width: 580px;
                }

                .left {
                    width: 250px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;

                    @media (max-width: 1024px) {
                        width: 100%;
                        align-items: center;
                    }

                    p {
                        @include TypoOutReg();
                        font-size: 17px;
                        color: #002b2a;
                        line-height: 150%;

                        @media (max-width: 1024px) {
                            text-align: center;
                        }
                    }
                    .img {
                        width: 200px;
                        margin-top: 60px;
                        transform: rotate(180deg);

                        @media (max-width: 768px) {
                            margin-top: 30px;
                        }
                    }
                }
                .right {
                    width: 250px;

                    @media (max-width: 1024px) {
                        display: none;
                    }
                }
            }
        }
    }

    .slider {
        position: relative;
        width: 100%;
        padding: 0 100px;
        margin-bottom: 60px;

        @media (max-width: 1024px) {
            padding: 0 17px;
        }
        @media (max-width: 768px) {
            margin-bottom: 30px;
        }
    }

    .addresse {
        position: relative;
        width: 100%;
        padding: 0 100px;
        margin-bottom: 110px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        @media (max-width: 1024px) {
            flex-direction: column;
            align-items: center;
        }
        @media (max-width: 768px) {
            margin-bottom: 40px;
            padding: 0 20px;
        }

        h2 {
            @include TypoPlayBold();
            font-size: 40px;
            color: #002b2a;
            line-height: 130%;
            max-width: 375px;

            @media (max-width: 1440px) {
                font-size: 30px;
            }
            @media (max-width: 1024px) {
                text-align: center;
                max-width: 425px;
            }
            @media (max-width: 768px) {
                font-size: 20px;
            }
        }

        p {
            width: 50%;
            @include TypoOutReg();
            font-size: 17px;
            color: #002b2a;
            line-height: 150%;
            margin-top: 15px;

            @media (max-width: 1024px) {
                width: 100%;
                max-width: 550px;
                text-align: center;
            }
        }
    }

    .container-tableau {
        width: 100%;
        background-color: #002b2a;
        padding: 95px 105px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        @media (max-width: 1024px) {
            padding: 60px 17px;
        }
        @media (max-width: 400px) {
            padding: 60px 5px;
        }

        h2 {
            max-width: 550px;
            @include TypoPlayBold();
            font-size: 40px;
            color: #ffffff;
            text-align: center;

            @media (max-width: 1024px) {
                font-size: 30px;
                max-width: 450px;
            }
            @media (max-width: 768px) {
                font-size: 20px;
                max-width: 280px;
            }
        }
        .img {
            width: 200px;
            transform: rotate(180deg);
            margin: 45px 0;
        }
        .tableau {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;

            @media (max-width: 768px) {
                display: none;
            }

            .typo {
                width: 24%;
            }
            .surface {
                width: 24%;
            }
            .prix {
                width: 37%;
            }
            .plan {
                width: 10%;
            }
            .typo,
            .surface,
            .prix,
            .plan {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;

                .title {
                    width: 100%;
                    height: 80px;
                    background-color: #9bae90;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 15px;
                    border-radius: 6px;

                    p {
                        @include TypoOutBold();
                        font-size: 20px;
                        color: #ffffff;
                        text-transform: uppercase;

                        @media (max-width: 1024px) {
                            font-size: 18px;
                        }
                    }
                    span {
                        @include TypoOutReg();
                        font-size: 14px;
                        color: #ffffff;
                        margin-top: 5px;

                        @media (max-width: 1024px) {
                            font-size: 12px;
                        }
                    }
                }
                .item {
                    width: 100%;
                    height: 80px;
                    margin-bottom: 15px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: transparent;
                    border: 1px solid #9bae90;
                    border-radius: 6px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .typo .item {
                p {
                    @include TypoOutBold();
                    font-size: 24px;
                    color: #ffffff;

                    @media (max-width: 1024px) {
                        font-size: 20px;
                    }
                }
                span {
                    @include TypoOutReg();
                    font-size: 14px;
                    color: #ffffff;
                    margin-top: 2px;

                    @media (max-width: 1024px) {
                        font-size: 12px;
                    }
                }
            }
            .surface .item p {
                @include TypoOutReg();
                font-size: 20px;
                color: #ffffff;

                @media (max-width: 1024px) {
                    font-size: 18px;
                }
            }
            .prix {
                .item {
                    background-color: #ffffff;
                    border: none;

                    p {
                        @include TypoOutBold();
                        font-size: 32px;
                        color: #002b2a;

                        @media (max-width: 1024px) {
                            font-size: 28px;
                        }
                    }
                }
            }
            .plan {
                .item {
                    border: none;
                }
                a {
                    width: 100%;
                    height: 100%;
                    border-radius: 6px;
                    border: 1px solid #9bae90;
                    background-color: transparent;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.3s ease-in-out;
                    padding-bottom: 10px;

                    .fleche {
                        width: 11px;
                        margin: 0 auto;
                        transform: translateY(10px);
                        transition: all 0.3s ease-in-out;
                    }
                    .barre {
                        width: 25px;
                    }
                    .green {
                        fill: #ffffff;
                        transition: all 0.3s ease-in-out;
                    }

                    &:hover {
                        background-color: #ffffff;
                        .fleche {
                            transform: translateY(0px);
                        }
                        .green {
                            fill: #9bae90;
                        }
                    }
                }
            }
        }

        .tableau-mob {
            display: none;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            @media (max-width: 768px) {
                display: flex;
            }

            .title {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                height: 60px;
                margin-bottom: 20px;

                .item {
                    background-color: #9bae90;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-radius: 6px;
                    padding: 0 15px;

                    @media (max-width: 400px) {
                        padding: 0 10px;
                    }

                    p {
                        @include TypoOutBold();
                        font-size: 18px;
                        color: #ffffff;
                        text-transform: uppercase;

                        @media (max-width: 400px) {
                            font-size: 16px;
                        }
                    }

                    &:nth-child(2n) {
                        margin: 0 10px;
                    }
                }
            }
            .group {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }
                .top {
                    width: 100%;
                    background-color: transparent;
                    border: 1px solid #9bae90;
                    border-radius: 6px;
                    height: 60px;
                    margin-bottom: 10px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    .typo,
                    .surface {
                        width: 130px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        height: 100%;

                        @media (max-width: 400px) {
                            width: 100px;
                        }

                        p {
                            @include TypoOutBold();
                            font-size: 14px;
                            color: #ffffff;

                            @media (max-width: 400px) {
                                font-size: 12px;
                            }

                            sup {
                                font-size: 10px;

                                @media (max-width: 400px) {
                                    font-size: 8px;
                                }
                            }
                        }
                    }
                    .typo span {
                            @include TypoOutReg();
                            color: #ffffff;
                            font-size: 12px;
                            line-height: 16px;
                    }
                    .plan {
                        width: 80px;
                        height: 60px;

                        @media (max-width: 400px) {
                            width: 60px;
                        }

                        a {
                            width: 100%;
                            height: 100%;
                            text-decoration: none;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            .img {
                                transform: none;
                                width: 25px;

                                @media (max-width: 400px) {
                                    width: 20px;
                                }
                            }
                        }
                    }
                }
                .bot {
                    width: 100%;
                    height: 60px;
                    background-color: #ffffff;
                    border-radius: 6px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    p {
                        @include TypoOutBold();
                        font-size: 18px;
                        color: #002b2a;
                    }
                    span {
                        @include TypoOutReg();
                        font-size: 9px;
                        color: #002b2a;
                    }
                }
            }
        }
    }

    .map {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        height: 696px;
        padding: 16px;

        @media (max-width: 1440px) {
            justify-content: space-between;
        }
        @media (max-width: 1024px) {
            flex-direction: column-reverse;
            align-items: center;
            justify-content: flex-start;
            height: auto;
        }

        .left {
            width: 696px;
            height: 100%;
            margin-right: 16px;
            background-color: #9bae90;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            @media (max-width: 1440px) {
                width: 49%;
                margin-right: 0;
                padding: 20px;
            }
            @media (max-width: 1024px) {
                margin-top: 17px;
                width: 100%;
            }
            @media (max-width: 768px) {
                padding: 10px;
            }

            h2 {
                @include TypoPlayBold();
                font-size: 30px;
                color: #ffffff;
                text-align: center;
                margin-top: 50px;
                text-transform: uppercase;

                @media (max-width: 1440px) {
                    font-size: 25px;
                    margin-top: 30px;
                }
                @media (max-width: 1024px) {
                    font-size: 30px;
                }
                @media (max-width: 768px) {
                    font-size: 17px;
                }
            }
            .addr {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                margin-top: 30px;
                margin-bottom: 35px;

                @media (max-width: 1440px) {
                    margin-top: 25px;
                }
                @media (max-width: 768px) {
                    margin-bottom: 20px;
                    margin-top: 20px;
                }

                .img {
                    width: 18px;
                    margin-bottom: 0;
                    margin-right: 16px;

                    @media (max-width: 768px) {
                        width: 10px;
                        margin-right: 5px;
                    }
                }
                p {
                    @include TypoOutReg();
                    font-size: 22px;
                    color: #002b2a;
                    text-transform: uppercase;

                    @media (max-width: 1440px) {
                        font-size: 20px;
                    }
                    @media (max-width: 768px) {
                        font-size: 12px;
                    }
                }
            }
            .img {
                width: 200px;
                margin-bottom: 60px;

                @media (max-width: 1440px) {
                    margin-bottom: 40px;
                }
                @media (max-width: 768px) {
                    width: 100px;
                    margin-bottom: 30px;
                }
            }
            .item {
                max-width: 550px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;

                &:nth-child(2n+1) {
                    margin-top: 25px;

                    @media (max-width: 1440px) {
                        margin-top: 20px;
                    }
                }

                p {
                    @include TypoOutBold();
                    font-size: 17px;
                    color: #002b2a;

                    @media (max-width: 1440px) {
                        font-size: 16px;
                    }
                    @media (max-width: 1024px) {
                        font-size: 18px;
                    }
                    @media (max-width: 768px) {
                        font-size: 12px;
                    }
                }
                span {
                    @include TypoOutReg();
                    font-size: 17px;
                    color: #ffffff;
                    margin-top: 15px;
                    text-align: center;
                    line-height: 150%;

                    @media (max-width: 1440px) {
                        font-size: 16px;
                    }
                    @media (max-width: 1024px) {
                        font-size: 18px;
                    }
                    @media (max-width: 768px) {
                        font-size: 12px;
                    }
                }
            }
            span {
                @include TypoOutReg();
                font-size: 14px;
                color: #ffffff;
                margin-top: 60px;

                @media (max-width: 768px) {
                    font-size: 9px;
                }
            }
        }
        .right {
            width: 696px;
            height: 100%;
            background-image: url("/images/map.png");
            background-position: 50% 50%;
            background-size: cover;
            background-repeat: no-repeat;

            @media (max-width: 1440px) {
                width: 49%;
            }
            @media (max-width: 1024px) {
                width: 100%;

                .img {
                    width: 100%;
                }
            }
        }
    }
    .mini-mob {
        display: none;
        width: 100px;
        margin-top: 10px;

        @media (max-width: 570px) {
            display: block;
        }
    }
    .footer {
        width: 100%;
        padding: 4px 65px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 1024px) {
            padding: 10px;
        }
        @media (max-width: 350px) {
            flex-direction: column-reverse;
            align-items: center;
            justify-content: flex-start;
        }

        .copro {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            p {
                @include TypoOutBold();
                font-size: 14px;
                color: #002b2a;

                @media (max-width: 1024px) {
                    font-size: 10px;
                }
            }

            .bot {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-top: 15px;

                .img {
                    width: 130px;

                    @media (max-width: 1024px) {
                        width: 60px;
                    }
                }

                .line {
                    width: 1px;
                    height: 105px;
                    margin-left: 20px;
                    margin-right: 13px;
                    background-color: #002b2a;

                    @media (max-width: 1024px) {
                        height: 65px;
                    }
                }
            }
        }
        .ecrin {
            width: 170px;

            @media (max-width: 1024px) {
                width: 100px;
            }
        }
        .mini-desk {
            display: block;

            @media (max-width: 570px) {
                display: none;
            }
        }
        .rdv {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            @media (max-width: 350px) {
                margin-bottom: 10px;
            }

            p {
                @include TypoOutBold();
                font-size: 20px;
                color: #9bae90;

                @media (max-width: 1024px) {
                    font-size: 10px;
                }
            }
            a {
                @include TypoOutBold();
                font-size: 40px;
                font-weight: 600;
                color: #002b2a;
                padding: 5px;
                text-decoration: none;

                @media (max-width: 1024px) {
                    font-size: 20px;
                }
            }
            span {
                @include TypoOutReg();
                font-size: 14px;
                color: #002b2a;

                @media (max-width: 1024px) {
                    font-size: 8px;
                }
            }
        }
    }
}
.bandeau {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #000000;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    transform: translateY(-75px);
    transition: all 0.3s ease-in-out;

    .logo {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media (max-width: 768px) {
            align-items: flex-start;
            padding-left: 40px;
        }

        .img {
            width: 110px;

            @media (max-width: 768px) {
                width: 100px;
            }
        }
    }
    .contact {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding: 10px 40px;

        .anim {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            @media (max-width: 768px) {
                display: none;
            }

            &:hover {
                .tel a {
                    transform: translateX(0);
                }
                .line {
                    height: 40px;
                }
            }

            .line {
                height: 0px;
                width: 2px;
                background-color: #9bae90;
                transition: all 0.3s ease-in-out;
            }
            .tel {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                margin-right: 20px;
                overflow: hidden;

                a {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    text-decoration: none;
                    transform: translateX(144px);
                    transition: all 0.3s ease-in-out;
    
                    .img {
                        width: 29px;
                        margin-right: 15px;
                    }
                    p {
                        @include TypoOutBold();
                        font-size: 20px;
                        color: #002b2a;
                        
                    }
                }
            }
        }
        .mail {
            margin-left: 20px;
            width: 30px;
            cursor: pointer;
        }
        .telephone {
            width: 30px;
            display: none;

            @media (max-width: 768px) {
                display: block;
            }
        }
    }
}
img {
    width: 100%;
    height: auto;
}
.desk {
    display: flex !important;

    @media (max-width: 1024px) {
        display: none !important;
    }
}
.mob {
    display: none !important;

    @media (max-width: 1024px) {
        display: flex !important;
    }
}