@import "../../node_modules/normalize.css/normalize.css";
//@import "../../node_modules/bootstrap/dist/css/bootstrap.css";
@import "~bootstrap/scss/bootstrap";
// @import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
// @import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

//@import "partials/mixins";
//@import "partials/app";
// COULEURS //
//////////////

$WForm: 475px;

@mixin TypoPlayReg {
  font-family: 'Playfair Display', serif;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@mixin TypoPlayBold {
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@mixin TypoOutReg {
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@mixin TypoOutBold {
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}


@mixin transition-property($property) {
  -webkit-transition-property: $property;
  -moz-transition-property: $property;
  -o-transition-property: $property;
  transition-property: $property;
}
@mixin transition-duration($time) {
  -webkit-transition-duration: $time;
  -moz-transition-duration: $time;
  -o-transition-duration: $time;
  transition-duration: $time;
}
@mixin transition-function($function) {
  -webkit-transition-timing-function: $function;
  -moz-transition-timing-function: $function;
  -o-transition-timing-function: $function;
  transition-timing-function: $function;
}
@mixin transition-delay($function) {
  -webkit-transition-delay: $function;
  -moz-transition-delay: $function;
  -o-transition-delay: $function;
  transition-delay: $function;
}
@mixin FullCenter() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@import "partials/reset";
@import "partials/color";
// @import "partials/contenu";
@import "partials/footer";
// @import "partials/navbar";
// @import "partials/burger";
// @import "partials/radio/switchOptin";
// @import "partials/button/basic";
// @import "partials/btn";
// @import "partials/mentions";
@import "partials/tarteaucitron";

// Listes
// @import "partials/liste/liste-image";
// @import "partials/liste/liste-centre-image";
@import "partials/visuel/visuel-slider";
// Optin & Civilité

@import "partials/radio/basicCiv";
// @import "partials/radio/switchCiv";
@import "partials/radio/basicOptin";
// @import "partials/radio/switchOptin";
@import "partials/form";
// Bandeau
// @import "partials/bandeau-footer/tel-real";
// @import "partials/bandeau-footer/copro-tel";

// Footer
@import "partials/modals";

@import "partials/arpajon";

@import "partials/button/basic";
// Header
// @import "partials/header/sticky-menu";

// Custom
// @import "partials/custom/bloc-bv";